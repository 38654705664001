
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {},
})
export default class Carousel extends Vue {
  selectedSlide = 1
  intervalId = -1

  mounted() {
    this.intervalId = this.createFetchInterval();
  }

  createFetchInterval() {
    return window.setInterval(() => this.changeSlide(this.selectedSlide === 4 ? 1 : this.selectedSlide + 1), 5000);
  }

  destroyFetchInterval() {
    return clearInterval(this.intervalId);
  }

  changeSlide(slide: number, type?: 'manual' | '') { 
    this.selectedSlide = slide
    const radio = window.document.getElementById('slide' + this.selectedSlide)! as HTMLInputElement
    radio.checked = true;
    if(type === 'manual') {
      this.destroyFetchInterval()
      this.intervalId = this.createFetchInterval()
    }
  }
}
